import { TESTERS_DELIVERY_VIP, TESTERS_CARDAPIO_WEB, TESTERS_JOTA_JA, TESTERS_ANOTA_AI} from "@Routes/Parceiro/BetaTesters";

const RoutesParceiroPadrao = [
    {
        path: "dashboard/p",
        dashboard: "Parceiro/Padrao",
        prefix: "Meu Fidelizi",
        title: "Dashboard",
        children: [
            {
                path: ":idParceiro",
                abstract: true,
                component: "/Padrao",
                children: [
                    {
                        index: true,
                        component: "/Home",
                        title: 'Meu Dashboard',
                        description: "Acompanhe as métricas mais importantes do seu Fidelizi e tenha insights poderosos.",
                    },
                    {
                        path: "metricas",
                        permissions: ['permissao_metricas'],
                        component: "/Metricas",
                        title: 'Métricas',
                        menu: {
                            order: 3,
                            icon: "fa fa-chart-area",
                            name: "Métricas",
                        },
                        children: [
                            {
                                path: "graficos",
                                component: "/Graficos",
                                title: "Métricas → Gráficos",
                                menu: {
                                    name: "Graficos",
                                },
                                description: "Analise graficamente as métricas do seu Fidelizi.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba interpretar seus gráficos',
                                        id: 6475586,
                                    },
                                ],
                            },
                            {
                                path: "nps",
                                component: "/RelatorioNps",
                                title: "Métricas → NPS",
                                menu: {
                                    name: "NPS",
                                },
                                description: "Descubra a satisfação dos seus clientes com o NPS Score.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre o seu NPS',
                                        id: 6985017,
                                    },
                                ],
                            },
                            {
                                path: "desempenho",
                                component: "/Desempenho",
                                title: "Métricas → Desempenho",
                                menu: {
                                    name: "Desempenho",
                                },
                                description: "Acompanhe os dados de desempenho do seu Fidelizi.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Entenda mais sobre o desempenho do seu Fidelizi',
                                        id: 6476637,
                                    },
                                ],
                            },
                            {
                                path: "dados-externos",
                                component: "/DadosExternos",
                                title: "Dados Externos",
                                menu: {
                                    name: "Dados Externos",
                                },
                                description: "Calibre o Fidelizi para obter relatórios de desempenho.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a inserir seus dados externos',
                                        id: 6254673,
                                    },
                                ],
                            },
                            {
                                path: "repeticao-de-compras",
                                component: "/RepeticaoCompras",
                                title: "Métricas → Repetição de compras",
                                menu: {
                                    name: "Repetição de compras",
                                },
                                description: "Veja o funil de repetição de compras desde o início e o tempo médio entre elas.",
                                beta: true
                            }
                        ],
                    },
                    {
                        path: "clientes",
                        permissions: ['permissao_clientes', 'permissao_transacoes'],
                        component: "/Clientes",
                        title: 'Clientes',
                        menu: {
                            order: 5,
                            icon: "fa fa-users",
                            name: "Clientes",
                        },
                        children: [
                            {
                                path: "",
                                redirect: "Lista",
                                title: "Clientes → Lista de Clientes",
                            },
                            {
                                path: "lista",
                                permissions: ['permissao_clientes'],
                                component: "/Lista",
                                title: "Clientes → Lista de Clientes",
                                menu: {
                                    name: "Lista de Clientes",
                                },
                                description: "Gerencie e consulte os dados de seus clientes.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Entenda as possibilidades da lista de clientes',
                                        id: 6310663,
                                    },
                                ],
                            },
                            {
                                path: "metricas",
                                permissions: ['permissao_clientes'],
                                component: "/Metricas",
                                title: "Clientes → Métricas",
                                menu: {
                                    name: "Métricas",
                                },
                                description: "Obtenha insights incríveis com os dados de persona de seus clientes.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre as métricas da sua base',
                                        id: 6319515,
                                    },
                                ],
                            },
                            {
                                path: "transacoes",
                                permissions: ['permissao_transacoes'],
                                component: "/Transacoes",
                                title: "Clientes → Transações",
                                menu: {
                                    name: "Transações",
                                },
                                description: "Saiba os detalhes de cada atividade ou transação dos seus clientes.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a analisar suas transações',
                                        id: 6319525,
                                    },
                                    // {
                                    //   type: 'tour', // article, tour, video
                                    //   text: 'Use agora',
                                    //   id: 393018,
                                    // },
                                ],
                            },
                            {
                                path: "segmentacoes",
                                permissions: ['permissao_clientes'],
                                component: "/Segmentacoes",
                                title: "Clientes → Segmentações",
                                menu: {
                                    name: "Segmentações",
                                },
                                description: "Segmente seus clientes usando combinações de filtros e critérios de busca.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Veja como criar uma segmentação',
                                        id: 6319533,
                                    },
                                ],
                                children: [
                                    {
                                        path: '',
                                        index: true,
                                        component: "/Lista",
                                    },
                                    {
                                        path: ":idSegmentacao",
                                        component: "/Editar",
                                    },
                                ]
                            },
                            {
                                path: "consumo",
                                permissions: ['permissao_clientes'],
                                component: "/Consumo",
                                title: "Clientes → Consumo Mensal",
                                beta: true,
                                menu: {
                                    name: "Consumo Mensal",
                                },
                                description: "Obtenha relatórios comparativos sobre as principais métricas do seu negócio.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba extrair relatórios de consumo mensal',
                                        id: 6319529,
                                    },
                                ],
                            },
                            {
                                path: ":idCliente",
                                component: "/GerenciarCliente",
                                title: "Clientes → Gerenciar Cliente",
                                description: "Permita-se ter insights incríveis com os dados de persona e receita dos seus principais perfis de clientes.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre as métricas da sua base',
                                        id: 6319515,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "premios-brindes",
                        permissions: ['permissao_premios'],
                        component: "/Premios",
                        title: 'Prêmios',
                        menu: {
                            order: 6,
                            icon: "fa fa-trophy",
                            name: "Prêmios",
                        },
                        children: [
                            {
                                path: "gerenciar",
                                component: "/Gerenciar",
                                title: "Prêmios & Brindes → Gerenciar",
                                description: "Gerencie, edite ou crie prêmios incríveis para o seu programa de fidelidade!",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a editar seus prêmios e muito mais!',
                                        id: 6319541,
                                    },
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'O que é o cashback do prêmio?',
                                        id: 8116699,
                                    },
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Prêmio do Programa',
                                        id: 7179935,
                                    },
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Prêmio Surpresa',
                                        id: 7179936,
                                    },
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Prêmio de Campanha',
                                        id: 7179938,
                                    },
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Brindes da Roleta',
                                        id: 7179939,
                                    },
                                ],
                                menu: {
                                    name: "Gerenciar",
                                },
                                children: [
                                    {
                                        path: "premios-do-programa",
                                        component: "/Tabs/PremiosDoPrograma",
                                        title: "Prêmios & Brindes → Gerenciar Prêmios do Programa",
                                    },
                                    {
                                        path: "brindes-da-roleta",
                                        component: "/Tabs/BrindesDaRoleta",
                                        title: "Prêmios & Brindes → Gerenciar Brindes da Roleta",
                                    },
                                    {
                                        path: "premios-de-campanha",
                                        component: "/Tabs/PremiosDeCampanha",
                                        title: "Prêmios & Brindes → Gerenciar Prêmios de Campanha",
                                    },
                                    {
                                        path: "premios-surpresa",
                                        component: "/Tabs/PremiosSurpresa",
                                        title: "Prêmios & Brindes → Gerenciar Prêmios Surpresa",
                                    },
                                    {
                                        path: "premios-de-jogos",
                                        component: "/Tabs/PremiosDeJogos",
                                        title: "Prêmios & Brindes → Gerenciar Prêmios de Jogos",
                                    },
                                ],
                            },
                            {
                                path: "relatorio",
                                component: "/Relatorio",
                                title: "Prêmios & Brindes → Relatório",
                                menu: {
                                    name: "Relatório",
                                },
                                description: "Saiba quais clientes estão resgatando prêmios e o status de resgate deles.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Entenda as informações do seu relatório',
                                        id: 6319539,
                                    },
                                ],
                            },
                            {
                                path: "desempenho",
                                component: "/Desempenho",
                                title: "Prêmios & Brindes → Desempenho",
                                menu: {
                                    name: "Desempenho",
                                },
                                description: "Analise de forma quantitativa o resgate dos seus prêmios.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba usar o relatório de desempenho',
                                        id: 6319534,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "atendentes",
                        permissions: ['permissao_atendentes'],
                        component: "/Atendentes",
                        title: 'Atendentes',
                        menu: {
                            order: 4,
                            icon: "fa fa-user",
                            name: "Atendentes",
                        },
                        children: [
                            {
                                path: "desempenho",
                                component: "/Desempenho",
                                title: "Atendentes → Desempenho",
                                menu: {
                                    name: "Desempenho",
                                },
                                description: "Acompanhe o desempenho da sua equipe e muito mais.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba acompanhar o desempenho do seu time',
                                        id: 6254813,
                                    },
                                ],
                            },
                            {
                                path: "metas",
                                component: "/Metas",
                                title: "Atendentes → Metas",
                                menu: {
                                    name: "Metas",
                                },
                                description: "Crie e gerencie diversas metas para a sua equipe",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a cadastrar metas',
                                        id: 6254698,
                                    },
                                ],
                            },
                            {
                                path: "gerenciar",
                                component: "/Gerenciar",
                                title: "Atendentes → Gerenciar",
                                menu: {
                                    name: "Gerenciar",
                                },
                                description: "Crie senhas de autorização para sua equipe e garanta análises de performance individual.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Veja como criar novas senhas',
                                        id: 6255694,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "configuracoes",
                        permissions: ['permissao_configuracoes'],
                        component: "/Configuracoes",
                        title: 'Configurações',
                        menu: {
                            order: 9,
                            icon: "fa fa-cog",
                            name: "Configurações",
                        },
                        children: [
                            {
                                path: "detalhes",
                                component: "/DetalhesEmpresa",
                                title: "Configurações → Dados do Estabelecimento",
                                description: "Atualize os principais dados cadastrais do seu negócio.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Entenda como atualizar os dados do seu negócio',
                                        id: 6319567,
                                    },
                                ],
                                menu: {
                                    name: "Dados do Estabelecimento",
                                },
                            },
                            {
                                path: "totem",
                                component: "/Totem",
                                title: "Configurações → Configurações do Totem",
                                description: "Gerencie os dados do seu totem Fidelizi.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a configurar o seu totem',
                                        id: 6319556,
                                    },
                                ],
                                menu: {
                                    name: "Totem",
                                },
                            },
                            {
                                path: "identidade-visual",
                                component: "/IdentidadeVisual",
                                title: "Configurações → Identidade Visual",
                                description: "Defina a identidade visual do seu Fidelizi.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba como definir sua identidade visual',
                                        id: 6319569,
                                    },
                                ],
                                menu: {
                                    name: "Identidade Visual",
                                },
                            },
                            {
                                path: "programa-fidelidade",
                                component: "/ProgramaFidelidade",
                                title: "Configurações → Programa de Fidelidade",
                                description: "Consulte ou edite as normas do seu Fidelizi.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba extrair o resumo dos seus prêmios',
                                        id: 6935012,
                                    },
                                ],
                                menu: {
                                    name: "Programa de Fidelidade",
                                },
                            },
                            {
                                path: "dispositivos",
                                component: "/Dispositivos",
                                title: "Configurações → Dispositivos",
                                description: "Gerencie todos os dispositivos conectados ao Fidelizi.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a configurar uma nova licença do Fidelizi Lite',
                                        id: 6319562,
                                    },
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Como usar o Fidelizi Lite',
                                        id: 6255754,
                                    },
                                ],
                                menu: {
                                    name: "Dispositivos",
                                },
                            },
                            {
                                path: "testadores",
                                component: "/Testadores",
                                title: "Configurações → Testadores ",
                                description: "Crie usuários de teste no seu Fidelizi.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba usar o recurso Testadores',
                                        id: 6319564,
                                    },
                                ],
                                menu: {
                                    name: "Testadores",
                                },
                            },
                            {
                                path: "email",
                                component: "/Email",
                                title: "Configurações → Email",
                                description: "Defina as principais informações dos e-mails transacionais.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Compreenda o uso das configurações do seu e-mail',
                                        id: 6319570,
                                    },
                                ],
                                menu: {
                                    name: "Email",
                                },
                            },
                            {
                                path: "banidos",
                                component: "/Banidos",
                                title: "Configurações → Banidos ",
                                description: "Impeça que um cliente participe do seu Fidelizi.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a banir um cliente',
                                        id: 6924586,
                                    },
                                ],
                                menu: {
                                    name: "Banidos",
                                },
                            },
                            {
                                path: "sms",
                                component: "/ConfigSMS",
                                title: "Configurações → SMS",
                                // menu: {
                                //     name: 'SMS'
                                // }
                            },
                            {
                                path: "importacoes",
                                component: "/Importacoes",
                                title: "Configuracoes → Importações",
                                description: "Importe os dados de seus clientes para o Fidelizi e comece a gerar pontos e prêmios para eles.",
                                // menu: {
                                //     name: 'Importações'
                                // }
                            },
                            {
                                path: "integracoes",
                                component: "/Integracoes",
                                title: "Configurações → Integrações",
                                description: "Configure integrações com sistemas parceiros, gerando chaves para automatização.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre as integrações Fidelizi',
                                        id: 6632927,
                                    },
                                ],
                                menu: {
                                    name: "Integrações",
                                },
                            },
                        ],
                    },
                    {
                        path: "financeiro",
                        permissions: ['permissao_financeiro'],
                        component: "/Financeiro",
                        title: "Financeiro",
                        description: "Consulte os dados financeiros do seu Fidelizi.",
                        guides: [
                            {
                                type: 'article', // article, tour, video
                                text: 'Saiba tudo sobre o seu financeiro.',
                                id: 6420969,
                            },
                        ],
                        menu: {
                            order: 8,
                            icon: "fa fa-credit-card",
                            name: "Financeiro",
                            title: "Financeiro",
                        },
                    },
                    {
                        path: "ferramentas",
                        permissions: ['permissao_ferramentas'],
                        component: "/Ferramentas",
                        title: 'Ferramentas',
                        menu: {
                            order: 7,
                            icon: "fa fa-wrench",
                            name: "Ferramentas",
                        },
                        children: [
                            {
                                path: "nova-campanha/:tipoCampanha",
                                component: "/NovaCampanha",
                                title: "Ferramentas → Nova Campanha",
                            },
                            {
                                path: "email-marketing",
                                component: "/EmailMarketing",
                                title: "Ferramentas → Email Marketing",
                                description: "Crie uma agenda de comunicação utilizando e-mails pontuais.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba como criar um e-mail marketing',
                                        id: 6319543,
                                    },
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a cadastrar o seu domínio',
                                        id: 6319545,
                                    },
                                ],
                                menu: {
                                    name: "Email Marketing",
                                },
                                children: [
                                    {
                                        path: '',
                                        component: "/Lista",
                                    },
                                    {
                                        path: ":idCampanha/editar/configuracoes-envio",
                                        component: "../ConstrutorEmail/Configuracoes",
                                        title: "Ferramentas → Email Marketing → Personalização do Email",
                                    },
                                    {
                                        path: ":idCampanha/editar/configuracoes-visual",
                                        component: "../ConstrutorEmail/VisualEmail",
                                        title: "Ferramentas → Email Marketing → Personalização do Email",
                                    },
                                    {
                                        path: ":idCampanha/editar",
                                        component: "../ConstrutorEmail",
                                        title: "Ferramentas → Email Marketing → Personalização do Email",
                                    },
                                    {
                                        path: ":idCampanha/relatorio/estatisticas",
                                        component: "../RelatorioEmails/Estatisticas",
                                        title: "Ferramentas → Email Marketing → Estatísticas",
                                    },
                                    {
                                        path: ":idCampanha/relatorio/regua-whatsapp",
                                        component: "../RelatorioEmails/Envios",
                                        title: "Ferramentas → Email Marketing → Envios & Régua de WhatsApp",
                                    },
                                ]
                            },
                            {
                                path: "sms/:idCampanha/editar",
                                component: "/ConstrutorSMS",
                                title: "Ferramentas → SMS → Personalização do SMS",
                            },
                            {
                                path: "piloto-automatico",
                                component: "/PilotoAutomatico",
                                title: "Ferramentas → Piloto Automático",
                                menu: {
                                    name: "Piloto Automático",
                                },
                                description: "Otimize seu fluxo de comunicação com e-mails dinâmicos.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a criar pilotos automáticos',
                                        id: 6319547,
                                    },
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Descubra como usar sua régua de whatsapp',
                                        id: 6555748,
                                    },
                                ],
                                children: [
                                    {
                                        path: '',
                                        component: "/Lista",
                                    },
                                    {
                                        path: ":idCampanha/editar/configuracoes-envio",
                                        component: "../ConstrutorEmail/Configuracoes",
                                        title: "Ferramentas → Piloto Automático → Personalização do Email",
                                    },
                                    {
                                        path: ":idCampanha/editar/configuracoes-visual",
                                        component: "../ConstrutorEmail/VisualEmail",
                                        title: "Ferramentas → Piloto Automático → Personalização do Email",
                                    },
                                    {
                                        path: ":idCampanha/relatorio/estatisticas",
                                        component: "../RelatorioEmails/Estatisticas",
                                        title: "Ferramentas → Piloto Automático → Estatísticas",
                                    },
                                    {
                                        path: ":idCampanha/relatorio/regua-whatsapp",
                                        component: "../RelatorioEmails/Envios",
                                        title: "Ferramentas → Piloto Automático → Envios & Régua de WhatsApp",
                                    },
                                ]
                            },
                            {
                                path: "whatsapp",
                                component: "/WhatsApp",
                                title: "Ferramentas → WhatsApp",
                                menu: {
                                    name: "WhatsApp",
                                },
                                description: "Explore o maior canal de comunicação dos brasileiros.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Aprenda a enviar mensagens via WhatsApp',
                                        id: 6319548,
                                    },
                                    // {
                                    //   type: 'tour', // article, tour, video
                                    //   text: 'Crie agora',
                                    //   id: 393130,
                                    // },
                                ],
                                children: [
                                    {
                                        path: '',
                                        title: "Ferramentas → WhatsApp",
                                        component: "/Lista",
                                    },
                                    {
                                        path: ":idCampanha/relatorio",
                                        component: "/Relatorio",
                                        title: "Ferramentas → WhatsApp → Disparos & Relatórios",
                                    },
                                    {
                                        path: ":idCampanha/editar",
                                        component: "/Editar",
                                        title: "Ferramentas → WhatsApp → Editar disparo",
                                    },
                                    {
                                        path: "novo",
                                        component: "/Editar",
                                        title: "Ferramentas → WhatsApp → Novo disparo",
                                    },
                                ]
                            },
                            {
                                path: "ia-conversacional",
                                component: "/IaConversacional",
                                title: "Clientes → Conversas I.A.",
                                // menu: {
                                //     name: "IA Conversacional",
                                // },
                                description: "Veja todas as conversas iniciadas pela nossa Inteligência Artificial.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Como funciona?',
                                        id: 6319548,
                                    },
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Como configurar?',
                                        id: 6319548,
                                    }
                                ]
                            },
                            {
                                path: "ofertas",
                                component: "/Ofertas",
                                title: "Ferramentas → Ofertas",
                                description: 'Crie e gerencie ofertas imperdíveis para seus clientes.',
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Crie uma oferta de cadastros',
                                        id: 6319549,
                                    },
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Oferta de indicação',
                                        id: 7791632,
                                    },
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Oferta sazonal',
                                        id: 7837247,
                                    },
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Oferta com tempo/cupons limitados',
                                        id: 7837672,
                                    },
                                ],
                                menu: {
                                    name: "Ofertas",
                                },
                                children: [
                                    {
                                        path: "",
                                        component: "/Lista",
                                        index: true,
                                    },
                                    {
                                        path: "nova",
                                        component: "/Editar",
                                        title: "Ferramentas → Ofertas → Nova Oferta",
                                    },
                                    {
                                        path: ":idOferta",
                                        component: "/Editar",
                                        title: "Ferramentas → Ofertas → Editar Oferta",
                                    },
                                    {
                                        path: ":idOferta/relatorio",
                                        component: "/Relatorio",
                                        title: "Ferramentas → Ofertas → Relatório da Oferta",
                                    },
                                ]
                            },
                            {
                                path: "pesquisas",
                                component: "/Pesquisas",
                                title: "Ferramentas → Pesquisas",
                                menu: {
                                    name: "Pesquisas",
                                },
                                description: "Utilize nossa ferramenta de pesquisa para obter dados valiosos.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Entenda o passo a passo da criação de pesquisas',
                                        id: 6319550,
                                    },
                                ],
                                children: [
                                    {
                                        path: '',
                                        title: "Pesquisas",
                                        component: "/Lista",
                                    },
                                    {
                                        path: "nova-pesquisa",
                                        component: "/NovaPesquisa",
                                        title: "Ferramentas → Pesquisas → Nova Pesquisa",
                                    },
                                    {
                                        path: ":idPesquisa/relatorio",
                                        component: "/Relatorio",
                                        title: "Ferramentas → Pesquisas → Relatorio",
                                        beta: true,
                                    },
                                    {
                                        path: ":idPesquisa/editar/configuracoes",
                                        component: "/Editar",
                                        title: "Ferramentas → Pesquisas → Configurações",
                                    },
                                    {
                                        path: ":idPesquisa/editar/configuracoes/perguntas",
                                        component: "/Editar/Perguntas",
                                        title: "Ferramentas → Pesquisas → Configurações",
                                    },
                                ]
                            },
                            {
                                path: "preco-fidelidade",
                                component: "/PrecoFidelidade",
                                title: "Preço Fidelidade",
                                description: "Incentive cadastros criando etiquetas de preços exclusivas.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: "Saiba usar o 'Preço Fidelidade'",
                                        id: 6785580,
                                    },
                                ],
                                menu: {
                                    name: "Preço Fidelidade",
                                },
                            },
                            {
                                path: "gerador-de-midias",
                                component: "/GeradorDeMidias",
                                title: "Gerador de Mídias",
                                menu: {
                                    name: "Gerador de Mídias",
                                },
                                description: "Gere material visual para suas redes sociais.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba usar o gerador de mídias',
                                        id: 6362575,
                                    },
                                ],
                            },
                            {
                                path: "jogos",
                                component: "/Game",
                                title: "Ferramentas → Jogos",
                                description: "Conquiste mais clientes com seu super jogo.",
                                menu: {
                                    name: "Jogos",
                                },
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre o Deblockz',
                                        id: 8218474,
                                    },
                                ],
                                beta: true,
                            },
                        ],
                    },
                    {
                        path: "indica",
                        component: "/Indica",
                        title: "Indica",
                        description: "Indique parceiros e ganhe prêmios!",
                        guides: [
                            {
                                type: 'article', // article, tour, video
                                text: 'Saiba usar o seu Indica',
                                id: 6319574,
                            },
                        ],
                        menu: {
                            order: 10,
                            icon: "fa fa-hand-point-right",
                            name: "Indica",
                            title: "Indica",
                        },
                    },
                    {
                        path: "vale-pontos",
                        component: "/ValePontos",
                        title: "Vale Pontos",
                        description: "Crie cupons para seus clientes e pontue-os usando códigos QRCode.",
                        guides: [
                            {
                                type: 'article', // article, tour, video
                                text: 'Aprenda a criar um vale pontos',
                                id: 6457996
                            },
                            {
                                type: 'article', // article, tour, video
                                text: 'Veja como ativar um cupom',
                                id: 6458596,
                            },
                        ],
                        menu: {
                            order: 1,
                            icon: "fa fa-qrcode",
                            name: "Vale Pontos",
                            title: "Vale Pontos",
                        },
                    },
                    {
                        path: "fidelizar",
                        component: "/Pontuar",
                        title: "Pontuar e Resgatar",
                        description: "Acesse os dados do cliente, pontue as compras e resgate prêmios.",
                        guides: [
                            {
                                type: 'article', // article, tour, video
                                text: 'Veja como pontuar seus clientes',
                                id: 6454895
                            },
                            {
                                type: 'article', // article, tour, video
                                text: 'Saiba estornar pontos',
                                id: 6254618
                            },
                            {
                                type: 'article', // article, tour, video
                                text: 'Aprenda a resgatar prêmios',
                                id: 6457172
                            },
                        ],
                        menu: {
                            order: 0,
                            icon: "fa fa-donate",
                            name: "Pontuar/Resgatar",
                            title: "Pontuar/Resgatar",
                        },
                    },
                    {
                        path: "integracoes-hotsites",
                        title: "Sites & Formulário de Cadastro",
                        component: "/IntegracaoHotsites",
                        description: "Gere o código para colocar o nosso formulário de cadastro em seu site, confira o link de seus hotsites.",
                    },
                    {
                        path: "sem-permissao",
                        title: "Sem Permissão",
                        component: "/SemPermissao",
                    },
                    {
                        path: "apps",
                        // permissions: ['permissao_configuracoes'],
                        component: "/Integracoes",
                        title: 'Integrações',
                        menu: {
                            order: 7,
                            icon: "fa fa-puzzle-piece",
                            name: "Integrações",
                        },
                        children: [
                            {
                                path: "ifood",
                                component: "/Ifood",
                                title: "Integrações → Ifood",
                                description: "Integre o Fidelizi com o Ifood e automatize o fluxo de pontuação dos clientes já cadastrados em seu delivery.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre a integração',
                                        id: 8174535
                                    },
                                ],
                                // menu: {
                                //     name: "Ifood ™",
                                //     showOn: [31],
                                // },
                                beta: true
                            },
                            {
                                path: "delivery-vip",
                                component: "/DeliveryVip",
                                title: "Integrações → Delivery Vip",
                                description: "Confira os dados da integração com a Delivery Vip.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre a sua integração',
                                        id: 7246537
                                    },
                                ],
                                menu: {
                                    name: "Delivery Vip",
                                    showOn: TESTERS_DELIVERY_VIP,
                                },
                                beta: true,
                            },
                            {
                                path: "cardapio-web",
                                component: "/CardapioWeb",
                                title: "Integrações → Cardapio Web",
                                description: "Confira os dados da integração com a Cardapio Web.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre a sua integração',
                                        id: 7246537
                                    },
                                ],
                                menu: {
                                    name: "Cardapio Web",
                                    showOn: TESTERS_CARDAPIO_WEB,
                                },
                                beta: true,
                            },
                            {
                                path: "jota-ja",
                                component: "/JotaJa",
                                title: "Integrações → Jotajá",
                                description: "Confira os dados da integração com a Jotajá.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre a sua integração',
                                        id: 7246537
                                    },
                                ],
                                menu: {
                                    name: "Jotajá",
                                    showOn: TESTERS_JOTA_JA,
                                },
                                beta: true,
                            },
                            {
                                path: "anota-ai",
                                component: "/AnotaAi",
                                title: "Integrações → Anota Ai",
                                description: "Confira os dados da integração com o Anota Ai.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre a sua integração',
                                        id: 7246537
                                    },
                                ],
                                menu: {
                                    name: "Anota Ai",
                                    showOn: TESTERS_ANOTA_AI,
                                },
                                beta: true,
                            },
                            {
                                path: "neemo",
                                component: "/Neemo",
                                title: "Integrações → Neemo",
                                description: "Confira os dados da integração com a Neemo Delivery.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre a sua integração',
                                        id: 7246537
                                    },
                                ],
                                menu: {
                                    name: "Neemo ™",
                                },
                            },
                            {
                                path: "stone",
                                component: "/Stone",
                                title: "Integrações → Stone",
                                description: "Confira os dados da integração com a Stone.",
                                guides: [
                                    {
                                        type: 'article', // article, tour, video
                                        text: 'Saiba tudo sobre a sua integração',
                                        id: 7246537
                                    },
                                ],
                                menu: {
                                    name: "Stone",
                                },
                            }

                        ]
                    }
                ],
            },
            {
                path: "*",
                title: "Não Encontrado",
                component: "/Page404",
            },
        ]
    }


];

export default RoutesParceiroPadrao;
