/**
 * Arquivo para ser centralizado IDS dos clientes Beta Testers das rotas do sistema
 * @type {*[]}
 */

/**
 * Lista de estabelecimentos que estarão realizando o teste do Delivery Vip
 * @type {number[]}
 */
export const TESTERS_DELIVERY_VIP = [31, 2276, 3879, 397];

/**
 * Lista de estabelecimentos que estarão realizando o teste do Delivery Vip
 * @type {number[]}
 */
export const TESTERS_CARDAPIO_WEB = [31, 2276, 3879, 397, 3114, 3116, 2952, 1756];

/**
 * Lista de estabelecimentos que estarão realizando o teste do Jota Já
 * @type {number[]}
 */
export const TESTERS_JOTA_JA = [31, 2276, 3879];

/**
 * Lista de estabelecimentos que estarão realizando o teste em relação a IA conversacional
 * @type {number[]}
 */
export const TESTERS_IA_CONVERSACIONAL = [31, 2276, 3879];

/**
 * Lista de estabelecimentos que estarão realizando o teste em relação a integração com a plataforma do Fala ae
 * @type {number[]}
 */
export const TESTERS_FALA_AE = [31, 2276, 3879];

/**
 * Lista de estabelecimentos que estarão realizando o teste do Anota Ai
 * @type {number[]}
 */
export const TESTERS_ANOTA_AI = [31, 2276, 3879];
