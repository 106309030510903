import { getCookie, removeCookie, setCookie } from '@Services/Cookies';
import { Plano } from '@Services/Helpers';
import WebApi from '@Services/WebApi';
import { findIndex } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import Swal from 'sweetalert2';

export const AuthContext = React.createContext({});

export const authCookie = "_fzat";
export const useAuth = () => React.useContext(AuthContext).Auth;


/////////////////////////////////////////////////////////////////////////////////////
// Reducer
/////////////////////////////////////////////////////////////////////////////////////
const INITIAL_STATE = {
  user: false,
  rede: false,
  parceiro: false,
  permissions: false,
  funcionalidades: false,
  intercom: false,
  isAuthenticated: getCookie(authCookie) ? true : false,
}

const reducer = (state = { ...INITIAL_STATE }, action) => {
  const { action: act, context, data } = action;

  if (act === 'logout') {
    return INITIAL_STATE;
  }

  if (act === 'set' && context && data !== undefined) {
    return { ...state, [context]: data }
  }
  if (act === 'update' && context && data !== undefined) {
    return { ...state, [context]: { ...state[context], ...data } }
  }

  return state;
}



/////////////////////////////////////////////////////////////////////////////////////
// Provider
/////////////////////////////////////////////////////////////////////////////////////
export const AuthProvider = React.memo(({ children }) => {
  const navigate = useNavigate();
  const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
  const {
    update: updateIntercom,
  } = useIntercom();

  // Shortcode do Dispatch
  const trigger = (action, context, data) => dispatch({ action, context, data });

  /////////////////////////////////////////////////////////////////////////////////////
  // Handlers
  /////////////////////////////////////////////////////////////////////////////////////
  const handleLogin = async (user) => {
    return await WebApi.v2.post('meu/login', user).then((response) => {
      const token = response?.data?.admin?.token;
      if (!token) { throw 'Ocorreu um erro ao obter seu token de acesso.'; }

      trigger('set', 'isAuthenticated', true);
      setCookie(authCookie, token);
      return true;
    }).catch(err => {
      console.log(err);
      throw err?.data?.errors[0] || err || 'Ocorreu um erro desconhecido. 😔'
    });
  }

  const handleLogout = (swalParams = undefined, stateParams = {}) => {
    dispatch({ action: 'logout' });
    if (swalParams) {
      Swal.fire({
        title: swalParams?.title || 'Atenção',
        text: swalParams?.text || 'Obrigado por utilizar o Fidelizi. Esperamos te ver em breve! 😃',
        icon: swalParams?.icon || 'success',
        timer: 5000,
        timerProgressBar: true,
      });
    }

    trigger('set', 'isAuthenticated', false);
    removeCookie(authCookie);

    return navigate("/", { state: { ...stateParams } });
  }

  const Auth = React.useMemo(() => ({
    user: state?.user,
    rede: state?.rede,
    isRede: state?.rede && state?.rede?.length > 1,
    isSuperAdmin: state?.user && ['fidelizii-master', 'fidelizii-gerente'].includes(state.user.permissao),
    isBloqueado: state?.parceiro?.status === 'bloqueado',
    isCashback: state?.parceiro?.promocao_atual?.tipo === 'cashback',
    isCielo: ['cielo-lite', 'cielo-pro', 'cielo-elite'].includes(state?.parceiro?.tipo_plano),
    parceiro: state.parceiro,
    cartelaModelo: state?.parceiro?.promocao_atual,
    permissions: state?.permissions,
    funcionalidades: state?.parceiro?.id_parceiro ? state?.funcionalidades : false,
    cookie: "_fzat",
    token: getCookie(authCookie),
    isAuthenticated: state.isAuthenticated,
    Login: (user) => handleLogin(user),
    Logout: (swalParams, stateParams) => handleLogout(swalParams, stateParams),
    SetUser: (user) => trigger('set', 'user', user),
    SetParceiro: (parceiro) => trigger('set', 'parceiro', parceiro),
    SetFuncionalidades: (funcionalidades) => trigger('set', 'funcionalidades', funcionalidades),
    SetRede: (rede) => trigger('set', 'rede', rede),
    SetIntercom: (data) => trigger('set', 'intercom', data),
    UpdateUser: (data) => trigger('update', 'user', data),
    UpdateParceiro: (data) => trigger('update', 'parceiro', data),
    UpdateRede: (data) => trigger('update', 'rede', data),
    UpdateIntercom: (data) => trigger('update', 'intercom', data),
  }), [state.user, state.parceiro, state.rede, state.intercom, state.permissions, state.funcionalidades, state.isAuthenticated]);


  /////////////////////////////////////////////////////////////////////////////////////
  // Effects - Maioria é pro Intercom
  /////////////////////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    if (state.intercom) updateIntercom(state.intercom);
  }, [state.intercom])

  React.useEffect(() => {
    if (state.user)
      Auth.UpdateIntercom({
        userId: state.user.id_administrador,
        name: state.user.nome,
        email: state.user.email,
        createdAt: state.user.created_at,
        userHash: state.user.hash,
        avatar: {
          type: "avatar",
          imageUrl: state.user?.metadata?.avatar || 'https://fidelizii.s3.sa-east-1.amazonaws.com/image/wizard-profile.png',
        },
      });
  }, [state.user])

  React.useEffect(() => {
    if (state.parceiro && !Auth.isSuperAdmin) {
      const rede = [...state.intercom.companies];
      const parceiro = { ...state.parceiro };
      const index = findIndex(rede, { companyId: parceiro.id_parceiro });
      const data = {
        companyId: parceiro.id_parceiro,
        createdAt: parceiro.created_at,
        plan: Plano(parceiro.tipo_plano),
        name: parceiro.nome,
        website: `https://meu.fidelizi.com.br/dashboard/${parceiro.id_parceiro}`,
        customAttributes: {
          CNPJ: parceiro.cnpj,
          Status: parceiro.status.toUpperCase(),
        }
      }


      if (index) {
        rede[index] = data
      } else {
        rede.push(data);
      }

      Auth.UpdateIntercom({ companies: rede });
    }
  }, [state.parceiro])




  /////////////////////////////////////////////////////////////////////////////////////
  // Render()
  /////////////////////////////////////////////////////////////////////////////////////
  return (
    <AuthContext.Provider value={{ Auth }}>
      {children}
    </AuthContext.Provider>
  );
});

